import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";

export default function Dashboard() {
  const naviget = useNavigate();
  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    naviget("/");
  }
  const user = localStorage.getItem("user");

  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() { 
    // axios
    //   .get("https://www.safqida.az/safqida/allDashboard.php/")
    //   .then(function (response) {
    //     console.log(response.data)
    //     setProducts(response.data);
    //   });
    axios.get('https://www.safqida.az/safqida/dashboard.php/', {
  })
  .then(response => {
      setProducts(response.data);
  })
  }

  const deleteUser = (id) => {
    axios
      .delete(`https://www.safqida.az/safqida/allDashboard.php/${id}/delete`)
      .then(function (response) {
        getProducts();
      });
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container"></div>
      </nav>
      <div className="container" style={{ paddingTop: 50 }}>
        <h3>{user}</h3>
        <div className="d-flex">
          <button
            className="btn btn-outline-primary"
            type="submit"
            onClick={logoutSubmit}
          >
            Logout
          </button>
        </div>
        <li>
          <Link to="/orders">Orders</Link>
        </li>
        <li>
          <Link to="/dashboard/create">Create Product</Link>
        </li>
        <div>
          <h1>List Products</h1>
          <table>
            <thead>
              <tr>
              <th>Actions</th>
                <th>ID</th>
                <th>Name</th>
                <th>Price</th>
                <th>Exist</th>
                <th>Category</th>
                <th>Country</th>
                <th>image</th>
              </tr>
            </thead>
            <tbody>
              {products.map((user, key) => (
                <tr key={key}>
                  <td>
                    <Link
                      to={`/dashboard/${user.id}/edit`}
                      style={{ marginRight: "10px" }}
                    >
                      Edit
                    </Link>
                    <button onClick={() => deleteUser(user.id)}>Delete</button>
                  </td>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.price}</td>
                  <td>{user.exist}</td>
                  <td>{user.category}</td>
                  <td>{user.country}</td>
                  <td>{user.image}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
