import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export default function EditOrder() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [inputs, setInputs] = useState({
    name: "",
    surname: "",
    country: "",
    location: "",
    phone: "",
    email: "",
    extra: "",
    products: "",
  });

  useEffect(() => {
    getOrder();
  }, []);

  function getOrder() {
    axios.get(`https://www.safqida.az/safqida/orders.php/${id}`)
      .then(response => {
        setInputs(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the order data!", error);
      });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.put(`https://www.safqida.az/safqida/orders.php/${id}/edit`, inputs)
      .then(response => {
        navigate("/orders");
      })
      .catch(error => {
        console.error("There was an error updating the order!", error);
      });
  };

  return (
    <div>
      <h1>Edit Order</h1>
      <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
          <tbody>
            <tr>
              <th><label>Name: </label></th>
              <td><input value={inputs.name} type="text" name="name" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Surname: </label></th>
              <td><input value={inputs.surname} type="text" name="surname" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Country: </label></th>
              <td><input value={inputs.country} type="text" name="country" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Location: </label></th>
              <td><input value={inputs.location} type="text" name="location" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Phone: </label></th>
              <td><input value={inputs.phone} type="text" name="phone" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Email: </label></th>
              <td><input value={inputs.email} type="text" name="email" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Extra: </label></th>
              <td><input value={inputs.extra} type="text" name="extra" onChange={handleChange} /></td>
            </tr>
            <tr>
              <th><label>Products: </label></th>
              <td><input value={inputs.products} type="text" name="products" onChange={handleChange} /></td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <button type="submit">Save</button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}
